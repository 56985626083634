export const english = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const arabic = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const bengali = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const gujarati = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const marathi = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const tamil = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const telugu = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const malayalam = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const hindi = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const kannada = {
  brandName: require("./logo.png"),
  background: require("./background.png"),
  logo: require("./logo.png"),
  trigger: require("./trigger.png"),
  poweredby: require("./poweredby.png"),
  lottiejson: require("./lottie.json"),
  lowRating: require("./lowRating.png"),
  midRating: require("./midRating.png"),
  highRating: require("./highRating.png"),
  outerBackground: require("./outerBackground.jpg"),
  user: require("./user.png"),
  feedbackIcons: [
    require("./angry.png"),
    require("./confounded.png"),
    require("./neutralFace.png"),
    require("./slightlySmilingFace.png"),
    require("./heartEyes.png"),
  ],
}

export const notification_sound = require("./notification.mp3")
