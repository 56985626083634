import React from "react"
import MinimizeIcon from "react-icons/lib/md/remove"
import MenuIcon from "react-icons/lib/md/menu"
import NextIcon from "react-icons/lib/md/navigate-next"
import PrevIcon from "react-icons/lib/md/navigate-before"
import ClearChatIcon from "react-icons//lib/md/refresh";
import CloseIcon from "react-icons/lib/md/close"
import ArrowDownwardIcon from "react-icons/lib/md/arrow-downward"

import "./chatbotStyle.scss"
import { translator } from "./brandSetup"

const chatbotStyle = {
  // ------------------------ classes ----------------------------
  containerMobileClass: "containerMobile",
  containerWebClass: "containerWeb",
  containerInAnimationClass: "ori-fade-in",
  containerOutAnimationClass: "ori-fade-out",
  msgBubbleClass: "ori-pad-7 ori-b-mrgn-5 ori-border-radius-10",
  firstBubbleClass: "",
  senderBubbleClass: "senderBubble",
  receiverBubbleClass: "receiverBubble",
  senderAvatarClass: "senderAvatar",
  receiverAvatarClass: "receiverAvatar",
  notificationSenderBubbleClass: "notificationSenderBubble",
  notificationReceiverBubbleClass: "notificationReceiverBubble",
  stackViewNotificationBubbleClass: "stackViewNotificationBubble",
  quickReplyWrapperClass:
    "ori-absolute ori-align-bottom-full quickReplyWrapper",
  stackViewCloseIconClass: "stackViewCloseIcon",
  agentPseudoNameClass: "ori-text-overflow-dotted agentPseudoName",
  headerContainerClass: "headerContainer",
  headerTagContainerClass:
    "ori-bg-header ori-lr-pad-10 ori-tb-pad-3 ori-box-shadow oriHeaderTagContainer",
  headerActionContainerClass: "oriHeaderActionContainer",
  footerContainerClass: "footerContainer",
  closeTriggerClass: "closeTrigger",
  defaultTriggerClass: "ori-animated ori-pulse defaultTrigger",
  lottieTriggerClass: "lottieTrigger",
  floatingTriggerClass: "floatingTrigger",
  floatingListClass: "ori-animated ori-fade-in floatingList",
  floatingCircularItemClass: "ori-animated ori-fade-in floatingCircularItem",
  endChatheaderClass: "endChatheader",
  //------------------------- styles --------------------------------
  chatbotContainer: {
    backgroundImage: `url(${
      translator.assets[translator.getLanguage()].background
    })`,
  },
  endChatContainer: {
    backgroundImage: `url(${
      translator.assets[translator.getLanguage()].background
    })`,
  },
  headerBorder: {},
  footerBorder: {},
  conversationContainer: {},
  stackViewConversationContainer: {
    cursor: "pointer",
    padding: "5px 30px 0px 40px",
  },
  receiverBubbleContainer: {},
  senderBubbleContainer: {},
  stackViewNotificationBot: {
    width: "320px",
    bottom: "85px",
    maxWidth: "100%",
  },

  //------------------------- components --------------------------------
  EndChatIcon: ({ lang }) => (
    <div className="ori-lr-pad-10 ori-border-radius-3 endChatIcon">
      {translator.text[lang].endChat}
    </div>
  ),
  MinimizeIcon: () => <MinimizeIcon size={20} className="headerIcon" />,
  ClearChatIcon: () => <ClearChatIcon  size={20} className="clearChatIcon" />,
  MenuIcon: () => <MenuIcon size={20} />,
  EndChatFeedbackCloseIcon: () => <CloseIcon className="headerIcon" size={14}/>,
  ScrollDownIcon: () => <ArrowDownwardIcon className="scrolldownIcon" size={18}/>,
  BelowFooter: () => null,
  MenuHeader: () => null,
  ShowMore: ({ lang }) => (
    <>
      {translator.text[lang].show_more} <NextIcon size={18} />
    </>
  ),
  ShowLess: ({ lang }) => (
    <>
      <PrevIcon size={18} />
      {translator.text[lang].show_less}
    </>
  ),
  OverflowShowMore: ({ lang }) => (
    <span className="ori-font-xs ori-font-light ori-cursor-ptr oriOverflowWrapperText">
      {translator.text[lang].overflow_showmore}
    </span>
  ),
}

export default chatbotStyle
